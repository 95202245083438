<template>
    <Layout>
     <PageHeader :title="title" :items="items" />
     <div class="container-fluid">
         <div class="row">
             <div class="card" id="filters">
                 <a href="#filters" class="uil-filter fa-2x text-primary" @click="showFilters=!showFilters">Filters</a>
                 <div class="row" v-if="showFilters">
                     <div class="col-sm-4">
                         <label>Business Location:</label>
                         <div class="mb-3">
                             <multiselect
                                 v-model="location"
                                 :options="locations"
                                 :multiple="false"
                                 label="name"
                                 track-by="name"
                                 placeholder="Select..."
                                 class=""
                             ></multiselect>
                        </div>
                     </div>
                     <div class="col-sm-4">
                         <div id="tickets-table-date-picker" class="m-2">
                         <label>
                         Filter by date:&nbsp;
                         <date-picker
                             class="form-input"
                             v-model="from"
                             :placeholder="new Date().toLocaleDateString()"
                             type="date"
                         ></date-picker>
                         </label>
                     </div>
                     </div>
                 </div>
             </div>
         </div>
     <div class="row">
         <div class="balance-sheet">
         <table class="table table-bordered">
         <thead class="bg-secondary text-white">
            <tr>
             <th scope="col">Trial Balance</th>
             <th scope="col">Debit</th>
             <th scope="col">Credit</th>
            </tr>
         </thead>
         <tbody>
            <tr v-for="(item, index) in balanceSheet" :key="index">
             <td>
                 <p><strong class="">{{ item.name }}</strong></p>
             </td>
             <td >
                <p v-if="item.category==='Credit'">
                    <strong>{{ formatCurrency(item.amount) }}</strong>
                </p>
             </td>
                <td>
                <p v-if="item.category==='Debit'">
                   <strong>{{ formatCurrency(item.amount) }}</strong>
                </p>
             </td>
            </tr>
         </tbody>
         <tfoot class="bg-secondary">
             <tr>
             <td class="text-white">Total</td>
             <td class="text-white"><strong>{{ formatCurrency(totalLiability) }}</strong></td>
             <td class="text-white"><strong>{{  formatCurrency(totalAssets) }}</strong></td>
             </tr>
         </tfoot>
         </table>
     </div>
     </div>
   </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import axios from '@/Axiosconfig';
  import Multiselect from "vue-multiselect";
 import "vue-multiselect/dist/vue-multiselect.min.css";
 import DatePicker from "vue2-datepicker";
 
  //import Swal from "sweetalert2";
 
  export default {
    page: {
    title: "Trial Balance Sheet",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader,Multiselect,DatePicker },
    data() {
      return {
        title: "Trial Balance",
        items: [
            {
            text: "Trial Balance",
            },
            {
            text: "Trial Balance Sheet",
            active: true,
            },
        ],
        showFilters:true,
        date:'',
        locations:[],
        location:null,
        business:null,
        balanceSheet: [
         { category: 'Credit', name: 'Supplier Due', amount: 0 },
         { category: 'Debit', name: 'Customer Due', amount: 0 },
         { category: 'Debit', name: 'Closing stock', amount: 0 },
       ],
     };
   },
   computed: {
     totalLiability() {
       return this.balanceSheet
         .filter(item => item.category === 'Credit')
         .reduce((total, item) => total + item.amount, 0)
         .toFixed(2);
     },
     totalAssets() {
       return this.balanceSheet
         .filter(item => item.category === 'Debit')
         .reduce((total, item) => total + item.amount, 0)
         .toFixed(2);
     }
   },   
  mounted() {
    this.business=JSON.parse(sessionStorage.business_location);
    //this.fetchBalanceSheet();
  },
  methods: {
    formatCurrency(amount) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        }).format(amount);
      },
     updatearrays(){
         axios.get(`locations/?business_name=${this.business.business__name}`)
           .then((res)=>{
             this.locations=res.data['results'].map(loc => ({
             ...loc,
             name: `${this.business.location_name} (${loc.location_id})`
              }));
              this.location=this.locations.find(l=>l.location_id===this.business.location_id);
           })
     },
      fetchBalanceSheet() {
        axios.get(`balancesheet`)
          .then(response => {
            this.balanceSheet = response.data;
          })
          .catch(error => {
            console.error('Error fetching account types:', error);
          });
      },
    }
  };
  </script>
  
  <style scoped>
  /* Add custom CSS styles here if needed */
  </style>
  